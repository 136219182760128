import axios from 'axios';

const BASE_URL = 'https://app.booksloth-apis.com/api';
// const BASE_URL = 'http://localhost:3000/api';
const token = '5ge5dr80vU3jo8AaCcCC25apVqpcdy8HadmK7UJh';
const signIn = async (email) => {
  const request = {
    method: 'GET',
    url: `${BASE_URL}/users/${email}`,
    headers: {'x-api-key': `${token}`}
  };
  return await axios(request).then((result) => {
    return result.data.user;
  }).catch((_) => {
    return null
  });
}
const signUp = async (email) => {
  let data = JSON.stringify({
    email: email,
    firstName: "",
    lastName: "",
    createdBy: "web",
    updatedBy: "web"
  });
  const request = {
    method: 'POST',
    url: `${BASE_URL}/users`,
    data: data,
    headers: {'x-api-key': `${token}`}
  };
  return await axios(request).then((result) => {
    return result.data.user;
  }).catch((_) => {
    return null
  });
}

export { signIn, signUp };
