import React, { useEffect, useContext } from 'react';
import BookItem from '../../CommonSections/BookItem';
import { getUserTopAuthors } from '../../../../_api/yearinreview';
import { AuthContext } from '../../../../_contexts/AuthContext';
import './style.css';

const getAuthors = async (userId, putAuthors) => {
	const result = await getUserTopAuthors(userId);
	if (result) {
		putAuthors(result);
	}
}

function TopAuthorsSection() {
	const { user, authors, putAuthors } = useContext(AuthContext);
	useEffect(() => {
		if (user.id && !authors) {
			getAuthors(user.id, putAuthors);
		}
	// eslint-disable-next-line
	}, [user.id]);
	
	if (authors && authors.length > 0) {
		return (
			<div className="Top-Authors-Section-Macro">
				<div className="Top-Authors-Section-Sub-Macro">
					<div className="Top-Authors-Section-Micro-A">
						<div className="Top-Authors-Section-Header-Text">Top Authors</div>
						<div className="Top-Authors-Section-Caption-Text">We know there are many amazing authors out there, looks like you have a few favorites. 
						Here are your most read authors on BookSloth.
						</div>
					</div>
					<div className="Top-Authors-Section-Micro-B">
						{authors.map((author, index) => (
							<BookItem item={author} key={index}/>
						))}
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
}

export default TopAuthorsSection;