import firebase from "firebase/app";
import "firebase/auth";

if (!firebase.apps.length) {
  firebase.initializeApp({
    "apiKey": "AIzaSyDYenFTGPoWIY9JKWxg8ZSaVyBB_ywUPQk",
    "authDomain": "booksloth-7e961.firebaseapp.com",
    "databaseURL": "https://booksloth-7e961.firebaseio.com",
    "projectId": "booksloth-7e961",
    "storageBucket": "booksloth-7e961.appspot.com",
    "messagingSenderId": "651469537114",
    "appId": "1:651469537114:web:fad0988fa45bb3d949bd63"
  });
}

const auth = firebase.auth();

export { firebase, auth };