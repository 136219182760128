/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import './style.css';
import FooterSection from "../../CommonSections/FooterSection";

const SocialMediaSection = () => {
	return (
		<FooterSection />
	);
}

export default SocialMediaSection;