import React, { useEffect, useContext } from 'react';
import 'firebase/auth';
import firebase from 'firebase/app';
import { AuthContext } from '../../_contexts/AuthContext'; 
import { ModalManager } from 'react-dynamic-modal';
import ModalLogin from '../Auth/ModalLogin';
// import ModalSignup from '../Auth/ModalSignup';
import './style.css';

const openLoginModal = () => {
    ModalManager.open(<ModalLogin onRequestClose={() => true} />);
};

// const openSignUpModal = () => {
//     ModalManager.open(<ModalSignup onRequestClose={() => true} />);
// };

const logout = () => {
    firebase.auth().signOut();
}

function onAuthStateChange(setUserNull) {
    return firebase.auth().onAuthStateChanged(firebaseUser => {
        if (!firebaseUser) {
            setUserNull({isAuth: false, email: ''});
        }
    });
}

const LoggedInNavBar = () => {
    const { setUserNull } = useContext(AuthContext);
    useEffect(() => {
        onAuthStateChange(setUserNull);
    }, [setUserNull]);
    
    return (
    <div className="Nav-Bar-Macro">
        <div className="Nav-Bar-Macro-Content">
            <div className="Nav-Bar-Text-Group">
                <a href="/" className="Nav-Bar-Side-Menu-Icon-Container" > 
                    <img className="Nav-Bar-Side-Menu-Icon" alt="" src="./menu-icon.svg" />
                </a>
                <a href="/"><img className="Nav-Bar-Logo" alt="" src="./booksloth_logo_b.png" /></a>
                <a href="https://medium.com/@booksloth" className="Nav-Bar-Link">Blog</a>
            </div>
            <div className="Nav-Bar-Button-Group">
                <button className="Nav-Bar-Button-outlined" onClick={() => {
                    logout()
                    setUserNull()
                }
                }>Log out</button>
            </div>
        </div>
    </div>
    );
}

const LoggedOutNavBar = () => {
    return (
    <div className="Nav-Bar-Macro">
        <div className="Nav-Bar-Macro-Content">
            <div className="Nav-Bar-Text-Group">
                <a href="/" className="Nav-Bar-Side-Menu-Icon-Container" > 
                    <img className="Nav-Bar-Side-Menu-Icon" alt="" src="./menu-icon.svg" />
                </a>
                <a href="/"><img className="Nav-Bar-Logo" alt="" src="./booksloth_logo_b.png" /></a>
                <a href="https://medium.com/@booksloth" className="Nav-Bar-Link">Blog</a>
            </div>
            <div className="Nav-Bar-Button-Group">
                {/* <button className="Nav-Bar-Button-contained" onClick={openSignUpModal.bind(this)}>Sign up</button> */}
                <button className="Nav-Bar-Button-outlined" onClick={openLoginModal.bind(this)}>Log in</button>
            </div>
        </div>
    </div>
    );
}

const NavigationBar = () => {
    const { user } = useContext(AuthContext);
    if (user.isAuth) {
        return (
            <LoggedInNavBar/> 
        ); 
    } else {
        return (
            <LoggedOutNavBar/>
        );
    }
}

export default NavigationBar;

