/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import './style.css';

const FooterSection = () => {
  return (
<div>
    <div className="footer-like-container">
      <div className="main-container">
        <div className="float-right">
					<h1>Like.</h1>
					<h1>Share.</h1>
					<h1>Follow.</h1>
					<h2>#bookslothapp</h2>
          	<div className="Social-Media-Icon-Container">
						<a href="https://www.facebook.com/booksloth/">
							<img className="Social-Media-Icon-Buttons" src="./facebook_logo_purple.png"/>
						</a>
						<a href="https://www.instagram.com/bookslothapp">
							<img className="Social-Media-Icon-Buttons" src="./instagram_logo_purple.png"/>
						</a>
						<a href="https://www.twitter.com/bookslothapp">
							<img className="Social-Media-Icon-Buttons last-child" src="./twitter_logo_purple.png"/>
						</a>
			</div>
		</div>
	</div>
		<div className="footer-company-container">
			<div className="Footer-Company-Section row">
				<div className="col-xs-12 col-md-6">
					<a href="/">
					<img className="Footer-Logo" src="./booksloth_logo_b.png"/>
				</a>
				<div className="Footer-Social-Container">
									<a href="https://www.facebook.com/booksloth/"><img className="Footer-Social-Icon" src="./facebook_logo.png"/></a>
									<a href="https://www.instagram.com/bookslothapp"><img className="Footer-Social-Icon instagram" src="./instagram_logo.png"/></a>
									<a href="https://www.twitter.com/bookslothapp"><img className="Footer-Social-Icon" src="./twitter_logo.png"/></a>
				</div>
				</div>
				<div className="col-xs-12 col-md-3">
					<h2>Company</h2>
					<a href="https://www.booksloth.com/" className="Footer-Company-Section-Text">About Us</a>
					<a href="https://medium.com/@booksloth" className="Footer-Company-Section-Text">Blog</a>
				</div>
				<div className="col-xs-12 col-md-3">
								<h2>Apps</h2>
								<a href="https://apps.apple.com/ng/app/booksloth/id1399024480?ign-mpt=uo%3D2" className="Footer-Company-Section-Text">iPhone</a>
								<a href="https://play.google.com/store/apps/details?id=com.booksloth.android" className="Footer-Company-Section-Text">Android</a>
				</div>           
			</div>
		</div>
	</div>
	<div className="Footer-Legal-Section">
		<div className="main-container">
			<div className="Footer-Legal-Section-Text">
        		<p>© 2018 BookSloth, Inc All rights reserved.</p>
        			<div className="footer-legal-terms float-xs-none float-md-right">
            		<a href="https://www.booksloth.com/terms/">Terms of use</a> 
            		<p>|</p>
            		<a href="https://www.booksloth.com/privacy/">Privacy</a>
        			</div>
    			</div>
			</div>
		</div>
</div>
  );
}

export default FooterSection;
