import React from "react";
import "./style.css";

const NoStatisticsSection = () => {
    return (
        <div className="No-Statistics-Macro-Background">
            <div className="No-Statistics-Sub-Macro-Background">
                <div className="No-Statistics-Text-Header">
                    We're still learning about your reading habits
                    </div>
                <img className="No-Statistics-Image" alt="" src="./sloth-climbing.svg"/>
                <div className="No-Statistics-Text-Caption">
                    Keep it up! Rate at least 10 books <br /> to get your personalized reader stats.<br/><br /> Start adding more books on the BookSloth app
                </div>
                <a href="https://booksloth.page.link/app"><button className="No-Statistics-Button">Add Books</button></a>
            </div>
        </div>
    )
}

export default NoStatisticsSection;