import React from 'react';
import "../../../../_helpers/bootstrap.min.css";

const BookSlothNumbers = () => {
    return (
      <div className="bs-numbers">
        <div className="main-container">
          <h1>BookSloth in Numbers</h1>
          <h2>
            We are extremely grateful for the BookSloth community. It was a privilege to help readers discover and share their love for books.
          </h2>
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <img className="fav-badge" alt="" src="./books-added.png" />
              <h1>
                <div className="number">350,000+ </div>
              </h1>
              <h2>Books Added</h2>
            </div>
            <div className="col-xs-12 col-md-4">
              <img className="fav-badge" alt="" src="./badge-3.png" />
              <h1>
                <div className="number">60,000+</div>
              </h1>
              <h2>Books Reviewed</h2>
            </div>
            <div className="col-xs-12 col-md-4">
              <img className="fav-badge" alt="" src="./books-rated.png" />
              <h1>
                <div className="number">145,000+ </div>
              </h1>
              <h2>Books Badges</h2>
            </div>
          </div>
        </div>
      </div>
    );
}

export default BookSlothNumbers;
