import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
	const [user, setUser] = useState({
		email: '', 
		firstName: '',
		id: null,
		isAuth: false,        
	});
	const [stats, setStats] = useState();
	const [authors, setAuthors] = useState();
	const [badges, setBadges] = useState();
	const [badgesGraph, setBadgesGraph] = useState();
	const [books, setBooks] = useState();
	const [dynamicLink, setDynamicLink] = useState();
	const [genres, setGenres] = useState();
	const [genresGraph, setGenresGraph] = useState();
	const [pagesGraph, setPagesGraph] = useState();
	const [profile, setProfile] = useState();
	const [ratingsGraph, setRatingsGraph] = useState();
	const customcolors = [
		"#8D57D4", "#673888", "#ef4f91", 
		"#1ebbd7", "#189ad3", "#8765F7"];
	const setUserNull = () => {
		setUser({
			email: '',
			firstName: '',
			id: null, 
			isAuth: false
		});
		setAuthors(null);
		setBadges(null);
		setBooks(null);
		setGenres(null);
		setStats(null);
	}
	const putUser = (user) => {
		setUser({
			email: user.email,
			firstName: user.first_name,
			id: user.id, 
			isAuth: true
		});
	};
	const putProfile = (profile) => {
		setProfile({
			userId: profile
		});
	}
	const putStats = (stats) => {
		setStats(stats);
	}
	const putAuthors = (authors) => {
		const items = [];
		authors.map((author) => (
			items.push({
				header: `${author.first_name} ${author.last_name}`, 
				caption: "",
				cover_photo: author.cover_photo
			})
		));
		setAuthors(items);
	};
	const putBadges = (badges) => {
		setBadges(badges); 
	};
	const putBadgesGraph = (badges) => {
		const items = [];
		badges.map((badge, index) => (
			items.push({
				name: badge.name,
				title: badge.badges_count,
				value: badge.badges_count,
				color: customcolors[index],
			})
		));
		setBadgesGraph(items);
	};
	const putPagesGraph = (pages) => {
		const items = [];
		pages.map((page, index) => (
			items.push({
				name: page.page_range,
				title: page.count,
				value: page.count,
				color: customcolors[index],
			})
		));
		setPagesGraph(items);
	};
	const putRatingsGraph = (ratings) => {
		const items = [];
		ratings.map((rating, index) => (
			items.push({
				name: rating.rating,
				title: rating.count,
				value: rating.count,
				color: customcolors[index],
			})
		));
		setRatingsGraph(items);
	};
	const putBooks = (books) => {
		const items = [];
		books.map((book) => (
			items.push({
				header: book.title, 
				caption: book.title,
				cover_photo: book.cover_photo
			})
		));
		setBooks(items);
	};
	const putDynamicLink = (link) => {
		setDynamicLink(link);
	};
	const putGenres = (genres) => {
		setGenres(genres);
	};
	const putGenresGraph = (genres) => {
		const items = [];
		genres.map((genre, index) => (
			items.push({
				name: genre.name,
				title: genre.genres_count,
				value: genre.genres_count,
				color: customcolors[index],
			})
		));
		setGenresGraph(items);
	};
	return (
		<AuthContext.Provider value={{
			user, 
			authors,
			badges,
			badgesGraph,
			books,
			dynamicLink, 
			genres,
			genresGraph,
			pagesGraph, 
			ratingsGraph,
			setUser,
			stats,
			profile,
			putUser, 
			putAuthors,
			putBadges,
			putBadgesGraph,
			putBooks,
			putDynamicLink,
			putGenres,
			putGenresGraph, 
			putPagesGraph,
			putProfile,
			putRatingsGraph,
			putStats,
			setUserNull
		}}>
		{props.children}
	</AuthContext.Provider>
	)
}

export default AuthContextProvider; 