import React, { useEffect, useContext } from 'react';
import BookItem from '../../CommonSections/BookItem';
import { AuthContext } from '../../../../_contexts/AuthContext';
import { getUserTopBooks } from '../../../../_api/yearinreview'; 
import "../../../../_helpers/bootstrap.min.css";
import './style.css';

const getBooks = async (userId, putBooks) => {
    const result = await getUserTopBooks(userId);
    if(result) {
      putBooks(result); 
    }
  }

const TopBooksSection = () => {
	const { user, books, putBooks } = useContext(AuthContext);
  useEffect(() => {
    if (user.id && !books) {
      getBooks(user.id, putBooks);
    }
  // eslint-disable-next-line
  }, [user.id]);
  
  if (books && books.length > 0) {	
	return (
    <div className="Top-Books-Section-Macro">
      <div className="Top-Books-Section-Sub-Macro">
        <div className="Top-Books-Section-Micro-A">
          <div className="Top-Books-Section-Header-Text">Top {books.length} books</div>
        </div>
        <div className="Top-Books-Section-Micro-B">
          {books.map((book, index) => (
            <BookItem item={book} key={index}/>
          ))}
        </div>
      </div>
    </div>
		); 
	} else {
		return null;
	}
}

export default TopBooksSection;