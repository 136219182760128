import axios from "axios";

// current year
const year = new Date().getFullYear()
const BASE_URL = 'https://app.booksloth-apis.com/api';
// const BASE_URL = 'http://localhost:3000/api';
const token = '5ge5dr80vU3jo8AaCcCC25apVqpcdy8HadmK7UJh';

// Default Values
const request = {
  method: 'GET',
  headers: {'x-api-key': `${token}`}
};

const getUserTopBadges = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/badges?top=3`;
  return await axios(request).then((result) => {
    return result.data;
  }).catch((_) => {
    return null
  });
}
const getUserBadgesGraph = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/badges?top=6`;
  return await axios(request).then((result) => {
    return result.data;
  }).catch((_) => {
    return null
  });
}

const getUserTopBooks = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/books?top=10`;
  return await axios(request).then((result) => {
    return result.data;
  }).catch((_) => {
    return null
  });
}
const getUserTopAuthors = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/authors?top=5`;
  return await axios(request).then((result) => {
    return result.data;
  }).catch((_) => {
    return null
  });
}
const getUserTopGenres = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/genres?top=3`;
  return await axios(request).then((result) => {
    return result.data;
  }).catch((_) => {
    // TODO: - Error Handling
    return null
  });
}
const getUserGenresGraph = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/genres?top=6`;
  return await axios(request).then((result) => {
    return result.data;
  }).catch((_) => {
    // TODO: - Error Handling
    return null
  });
}
const getTopBooks = async () => {
  request.url = `${BASE_URL}/yearinreview/${year}/books?top=10`;
  return await axios(request).then((result) => {
    return result.data;
  }).catch((_) => {
    return null
  });
}
const getTopAuthors = async () => {
  request.url = `${BASE_URL}/yearinreview/${year}/authors?top=3`;
  return await axios(request).then((result) => {
    return result.data;
  }).catch((_) => {
    return null
  });
}
const getUserStats = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/stats`;
  return await axios(request).then((result) => {
    return result.data.userResult;
  }).catch((_) => {
    return null
  });
}
const getUserRatingsGraph = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/graphs/rating`;
  return await axios(request).then((result) => {
    return result.data;
  }).catch((_) => {
    // TODO: - Error Handling
    return null
  });
}
const getUserPagesGraph = async (userId) => {
  request.url = `${BASE_URL}/yearinreview/${year}/users/${userId}/graphs/pages`;
  return await axios(request).then((result) => {
    return result.data;
  }).catch((_) => {
    // TODO: - Error Handling
    return null
  });
}
const getDynamicLink = async (userId) => {
  const request = {
    method: 'POST',
    url: "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDYenFTGPoWIY9JKWxg8ZSaVyBB_ywUPQk",
    data:
      {
        dynamicLinkInfo: {
          domainUriPrefix: "https://booksloth.page.link",
          link: `https://year-in-review.booksloth.com/${userId}`,
          socialMetaTagInfo: {
            socialTitle: "My Year in Books Review",
            socialDescription: "My Year in Books Review",
            socialImageLink:
              "http://booksloth-mobile-assets.s3.amazonaws.com/images/year-in-review-2020.png"
          }
        },
        suffix: {
          option: "SHORT"
        }
      }
    }
  return await axios(request).then((result) => {
    return result.data.shortLink;
  }).catch((_) => {
    return null
  });
}

export { 
  getTopBooks, 
  getTopAuthors,  
  getUserTopAuthors, 
  getUserTopBadges, 
  getUserBadgesGraph,
  getUserTopBooks, 
  getUserTopGenres, 
  getUserGenresGraph,
  getUserPagesGraph,
  getUserRatingsGraph,
  getUserStats, 
  getDynamicLink
};
