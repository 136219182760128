import React from "react";
import { PieChart } from 'react-minimal-pie-chart';

function GraphItem(props) {
    const { item } = props;
    return (
    <PieChart
        data={item}
        lineWidth={20}
        paddingAngle={5}
        label={({ dataEntry }) => Math.round(dataEntry.percentage) + '%'}
        labelStyle={(index) => ({
                fill: item[index].color,
                fontSize: '5px',
                fontFamily: 'sans-serif',
        })}
        labelPosition={60}
        style={{ height: '300px' }}
    />
    )
}

export default GraphItem;