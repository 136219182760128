import '../../../../_helpers/bootstrap.min.css';
import '../../../../_helpers/main.css';
import './style.css';

const BannerSection = () => {
    return (
    <div className="library-background-container">
        <div className="main-container">
            <div className="row">
                <div className="col-xs-12 col-md-6 rating-txt">
                <h1>Your year in books</h1>
                <p>Here's a look back at your reading journey: the authors you read and the books you loved and kept you company during these times.</p>
            </div>
            <div className="col-xs-12 col-md-6 rating-img">
                <img className="sloth-stats" alt="" src="a/i/sloth-stats.png" />
            </div>
        </div>
    </div>
    </div>
    );
}
export default BannerSection; 





