import React from "react";
import NavigationBar from "../../NavigationBar";
import HeaderSection from "./HeaderSection";
import DownloadSection from "./DownloadAppsSection";
import BannerSection from './BannerSection';
import BookSlothNumbers from '../CommonSections/BookSlothNumbersSection';
import BookSlothTopBooksSection from "../CommonSections/BookSlothTopBooksSection";
import SocialMediaSection from "../LoggedInStage/SocialMediaSection";

const LoggedOutStage = () => {
	return (
		<div>
			<NavigationBar />
			<HeaderSection />
			<DownloadSection />
			<BannerSection />
			<BookSlothNumbers />
			<BookSlothTopBooksSection />
			<SocialMediaSection />
		</div>
	);
}

export default LoggedOutStage;
