import React from "react";
import './style.css';

function BookItem(props) {
    const { item } = props;
    return (
        <div className="Book-Item-Macro">
            <div className="Book-Item-Micro-A">
                <img className="Book-Item-Image" alt="" src={item.cover_photo} />
            </div>
            <div className="Book-Item-Micro-B">
                <div className="Book-Item-Header">{item.header}</div>
                {/* <div className="Book-Item-Caption">{item.caption}</div> */}
            </div>
        </div>
    )
}

export default BookItem;