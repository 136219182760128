import React, { useEffect, useContext } from 'react';
import { getUserStats } from '../../../../_api/yearinreview'; 
import { AuthContext } from '../../../../_contexts/AuthContext'; 
import './style.css';

const getStats = async (userId, putStats) => {
  const result = await getUserStats(userId);
  if(result) {
    putStats(result); 
  }
}

const UserBookStatistics = () => {
    const { user, stats, putStats } = useContext(AuthContext);
    useEffect(() => {
        if (user.id && !stats) {
            getStats(user.id, putStats);
        }
    // eslint-disable-next-line
    }, [user.id]);
    if (stats) {
    return (
        <div className="UserBookStatistics-Section-Macro">
            <div className="UserBookStatistics-Section-Sub-Macro">
                <div className="UserBookStatistics-Section-Sub-Macro-Top">
                    <div className="UserBookStatistics-Section-Header">{user.firstName ?? user.email} completed <br />
                    <div className="stats-books-read">{stats.books_read ?? 0}</div> books in 2020</div>
                    <img className="UserBookStatistics-Section-Completed-Books" alt='' src="./books-completed-glyph.svg" />
                </div>
                <div className="UserBookStatistics-Section-Sub-Macro-Bottom">
                    <div className="UserBookStatistics-Section-Component">
                        <img className="UserBookStatistics-Section-Image" alt="" src="./pages-reviewed-glyph.svg" />
                        <div className="UserBookStatistics-Section-Count">{stats.pages_read ?? 0}</div>
                        <div className="UserBookStatistics-Section-Title">Pages Read</div>
                    </div>
                    <div className="UserBookStatistics-Section-Component">
                        <img className="UserBookStatistics-Section-Image" alt="" src="./books-reviewed-glyph.svg" />
                        <div className="UserBookStatistics-Section-Count" >{stats.reviews_count ?? 0}</div>
                        <div className="UserBookStatistics-Section-Title">Books Reviewed</div>
                    </div>
                    <div className="UserBookStatistics-Section-Component">
                        <img className="UserBookStatistics-Section-Image" alt="" src="./badges-given-glyph.svg" />
                        <div className="UserBookStatistics-Section-Count">{stats.badges_given}</div>
                        <div className="UserBookStatistics-Section-Title">Badges Given</div>
                    </div>
                </div>
            </div>
        </div>
    ); 
    } else {
        return null;
    }
}

export default UserBookStatistics;
