import React, { useState, useEffect } from 'react';
import AuthContextProvider from '../../_contexts/AuthContext'; 
import YearInReview from '../YearInReview';

import 'firebase/auth';
import firebase from 'firebase/app';

// Firebase auth: onAuthStateChanged
function onAuthStateChange(setFirebaseUser) {
  return firebase.auth().onAuthStateChanged(firebaseUser => {
    if (firebaseUser) {
      setFirebaseUser({isAuth: true, email: firebaseUser.email});
    } else {
      setFirebaseUser({isAuth: false, email: ''});
    }
  });
}

const App = () => {
  const [firebaseUser, setFirebaseUser] = useState({isAuth: false});
  useEffect(() => {
    onAuthStateChange(setFirebaseUser);
  }, []);
  return (
    <AuthContextProvider>
      <div>
        <YearInReview userEmail={firebaseUser.email} />
      </div>
    </AuthContextProvider>
  );
}

export default App;