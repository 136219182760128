import React, { useEffect, useContext } from "react";
import { AuthContext } from '../../../../_contexts/AuthContext';
import { getUserTopGenres } from '../../../../_api/yearinreview'; 
import "../../../../_helpers/bootstrap.min.css";

const getGenres = async (userId, putGenres) => {
  const result = await getUserTopGenres(userId);
  if(result) {
    putGenres(result); 
  }
}

const TopGenresSection = () => {
  const { user, genres, putGenres } = useContext(AuthContext);
  useEffect(() => {
    if (user.id && !genres) {
      getGenres(user.id, putGenres);
    }
  // eslint-disable-next-line
  }, [user.id]);

  if(genres && genres.length > 0) {
    return (
      <div className="steps top-genres">
        <div className="main-container">
           <h1>
              Top Genres
            </h1>
            <h2>
              Let's see what type of BookSloth reader you are.
              <br/>Here are the top genres you read.
            </h2>
          <div className="row">
            {genres.map((genre, index) => (
              <div className="col-xs-12 col-md-4" key={index}>
                <div className="top-genre">
                  <h1 className="number">{genre.genres_count}</h1>
                  <h2>{genre.name}</h2>
                  <img className="icon" src={genre.image} alt=""/>
                  <p>{genre.message}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default TopGenresSection;
