import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../../../_contexts/AuthContext';
import { getUserTopBooks } from '../../../_api/yearinreview'; 
import NavigationBar from '../../NavigationBar';
import HeaderSection from './HeaderSection';
import UserStatisticsSection from './UserStatisticsSection';
import GraphsSection from './GraphsSection';
import TopBooksSection from './TopBooksSection';
import TopGenresSection from './TopGenresSection';
import TopAuthorsSection from './TopAuthorsSection';
import TopBadgesSection from './TopBadgesSection';
import SharingSection from '../LoggedInStage/SharingSection';
import BookSlothNumbersSection from '../CommonSections/BookSlothNumbersSection';
import BookSlothTopBooksSection from '../CommonSections/BookSlothTopBooksSection';
import SocialMediaSection from '../LoggedInStage/SocialMediaSection';
import NoStatisticsSection from '../NoStatisticsSection';

const getBooks = async (userId, putBooks) => {
	const result = await getUserTopBooks(userId);
	if(result) {
		putBooks(result); 
	}
}

const SharedProfileStage = () => {
	const { user, books, putBooks } = useContext(AuthContext);
	useEffect(() => {
	  if (user.id && !books) {
		getBooks(user.id, putBooks);
      }
    // eslint-disable-next-line
	}, [user.id]);
	return (
		<div>
			<NavigationBar />
			<HeaderSection />
			<UserStatisticsSection/>
			<GraphsSection />
			<TopBooksSection />
			<TopGenresSection />
			<TopAuthorsSection />
			<TopBadgesSection />
			{books && books.length > 5 ? <SharingSection /> : <NoStatisticsSection />}
			<BookSlothNumbersSection />
			<BookSlothTopBooksSection />
			<SocialMediaSection />
		</div>
	);
}

export default SharedProfileStage;