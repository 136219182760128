import React, { useContext, useEffect } from "react";
import { AuthContext } from '../../../../_contexts/AuthContext';
import { getDynamicLink } from '../../../../_api/yearinreview';
import { FacebookShareButton, FacebookIcon } from "react-share";
import { TwitterShareButton, TwitterIcon } from "react-share";

const getLink = async (userId, putDynamicLink) => {
	const result = await getDynamicLink(userId);
	if (result) {
		putDynamicLink(result);
	}
}

const SharingSection = () => {
  const { user, dynamicLink, putDynamicLink } = useContext(AuthContext);
	useEffect(() => {
		if (user.id && !dynamicLink) {
			getLink(user.id, putDynamicLink);
    }
  // eslint-disable-next-line
  }, [user.id]);
  
  if (dynamicLink) {
    return (
      <div className="share-friends">
        <div className="main-container">
          <h1>Share with friends!</h1>
          <h2>There's no doubt your reading year has been amazing, let your friends know about it!</h2>
          <div className="social-media">
          <FacebookShareButton
            url={`${dynamicLink}`}
            quote={"My Year in Review"}
            hashtag="#booksloth">
            <FacebookIcon/>
          </FacebookShareButton>
          <TwitterShareButton
            url={`${dynamicLink}`}
            quote={"My Year in Review"}
            hashtag="#booksloth">
            <TwitterIcon/>
          </TwitterShareButton>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default SharingSection;
