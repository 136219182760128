import React, { useContext, useEffect } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { signIn } from '../../_api/user'; 
import { AuthContext } from '../../_contexts/AuthContext'; 
import LoggedOutStage from './LoggedOutStage';
import LoggedInStage from './LoggedInStage';
import SharedProfileStage from './SharedProfileStage';

const getUser = async (userEmail, putUser) => {
  const result = await signIn(userEmail);
  if(result) {
    putUser(result); 
  }
}

// const validateEmail = async (email) => {
//   const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(String(email).toLowerCase());
// }

const YearInReview = (props) => {  
  const { userEmail } = props;
  const { 
    user, putUser, setUser,
    profile, putProfile 
  } = useContext(AuthContext); 
  useEffect(() => {
    if (!profile && userIdPath) {
      putProfile(userIdPath);
      setUser({id: parseInt(userIdPath)});
    } else if (!profile && userEmail) {
      getUser(userEmail, putUser);
    }
  // eslint-disable-next-line
  }, [profile, userEmail]);

  const userIdPath = window.location.pathname.replace('/', '');
  if (userIdPath.length > 0) {
    return (
      <Router >
        <Route path={`/`} component={SharedProfileStage}></Route>
      </Router>
    )
  } else if (!user.id) {
    return (
      <Router>
        <Route path={`/`} component={LoggedOutStage}></Route>
      </Router>
    );
  } else {
    return (
      <Router>
        <Route path={`/`} component={LoggedInStage}></Route>
      </Router>
    )
  }
}

export default YearInReview;
