/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useContext } from "react";
import { AuthContext } from '../../../../_contexts/AuthContext';
import { getUserTopBadges } from '../../../../_api/yearinreview'; 
import './style.css';

const getBadges = async (userId, putBadges) => {
  const result = await getUserTopBadges(userId);
  if(result) {
    putBadges(result); 
  }
}

const TopBadgesSection = () => {
  const { user, badges, putBadges } = useContext(AuthContext);
  useEffect(() => {
    if (user.id && !badges) {
      getBadges(user.id, putBadges);
    }
  // eslint-disable-next-line
  }, [user.id]);

  if(badges && badges.length > 0) {
    return (
      <div className="top-badges">
        <div className="main-container">
          <h1>Top Badges
          </h1>
          <div className="row">
            {badges.map((badge, index) => (
              <div className="col-xs-12 col-md-4" key={index}>
                <img className="fav-badge" src={badge.icon} />
                <div className="notification number">
                  {badge.badges_count}
                </div>
                <h2>{badge.name}</h2>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default TopBadgesSection;
  