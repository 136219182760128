import React, { useEffect, useContext } from 'react';
import { getUserStats } from '../../../../_api/yearinreview'; 
import { AuthContext } from '../../../../_contexts/AuthContext'; 
import './style.css';

const getStats = async (userId, putStats) => {
  const result = await getUserStats(userId);
  if(result) {
    putStats(result); 
  }
}

const HeaderSection = () => {
    const { user, stats, putStats } = useContext(AuthContext);
    useEffect(() => {
        if (user.id && !stats) {
            getStats(user.id, putStats);
        }
    })
    if(stats) {
    return (
        <div className="Header-Section-Macro">
            <div className="Header-Section-Sub-Macro-Top">
                <div className="Header-Section-Sub-Title">{stats.first_name ?? stats.email}'s Year in Review</div>
                <div className="Header-Section-Title">2020</div>
                <div className="main-container">
                    <div className="Header-Section-Caption">We made it!
                    There's no doubt this year has been like no other, to say the least. 
                    As the world changed around us, at BookSloth we brought book lovers together and took comfort in reading. We want to highlight and take you through your reading journey with BookSloth this year.
                    </div>
                </div>
            </div>
        </div>
    )
    } else {
        return null;
    }
}

export default HeaderSection;    