import React from "react";
import './style.css';

const BookSlothTopBooksSection = () => {
    return (
        <div className="Top-Books-Macro-Container">
            <div className="Top-Books-div-Container">
                <div className="Top-Books-Header">
                    Top Books
                </div>
                <div className="Top-Books-Caption">
                    A lot happened in 2020, but one thing remained constant, our love for books. <br/> Here’s to the top authors and books that kept us company got us through this wild year.
                </div>
            </div>
            <div className="Top-Books-Image-Container">
                <img className="Top-Books-Image" alt="" src="./top_books_art.png" />
            </div>
        </div>
    )
}

export default BookSlothTopBooksSection;