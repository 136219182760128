import { ModalManager } from 'react-dynamic-modal';
import ModalLogin from '../../../Auth/ModalLogin';
// import ModalSignup from '../../../Auth/ModalSignup';
import '../../../../_helpers/main.css';

const openLoginModal = () => {
    ModalManager.open(<ModalLogin onRequestClose={() => true} />);
};

// const openSignUpModal = () => {
//     ModalManager.open(<ModalSignup onRequestClose={() => true} />);
// };

const HeaderSection = () => {
	return (
    <div className="year-hero-container">
		<div className="background-books">
			<div className="main-container">
				<div className="year-hero-text">
					<h1>2020</h1>
					<h2>BookSloth Review</h2>
					<p>There's no doubt this year has been like no other. 
                    As the world changed around us, we took comfort in reading.</p>
					<div className="sign-ups">
						{/* <button type="submit" className="sign-up" onClick={openSignUpModal.bind(this)}>Sign up</button> */}
						<button type="submit" className="log-in" onClick={openLoginModal.bind(this)}>Log in</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	);
}

export default HeaderSection;    