import React from "react";
import './style.css';

const DownloadAppsSection = () => {
    return (
        <div className="Download-Section-Macro-Container">
            <div className="Download-Section-Sub-Macro-A">
                <div className="Download-Section-Text">
                    Download Now
                </div>
            </div>
            <div className="Download-Section-Sub-Macro-B">
                <a
                    className="Download-Section-Button"
                    href="https://apps.apple.com/ng/app/booksloth/id1399024480?ign-mpt=uo%3D2"
                    onClick={e => this.handleClick("Download App iOS", e)}
                >
                    <img className="Download-Section-Button-Icon" alt="" src="./appstore-badge.svg" />
                </a>
                <a
                    className="Download-Section-Button"
                    href="https://play.google.com/store/apps/details?id=com.booksloth.android"
                    onClick={e => this.handleClick("Download App Android", e)}
                >
                    <img className="Download-Section-Button-Icon" alt="" src="./google-play-badge.svg" />
                </a>
            </div>
            <div className="Download-Section-Sub-Macro-C">
                <img className="Download-Section-Image" alt="" src="./devices.png" />
            </div>
        </div>
    )
}

export default DownloadAppsSection;